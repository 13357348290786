<template>
  <div>
    <multiple-row-header-table
      :title="title"
      :items="getItems"
      :columns="columns"
      :totalRows="totalRows"
      :perPage="perPage"
      :currentPage="currentPage"
      :api="api"
      :import="actions.import"
      :filterCourse="true"
      :apiImport="apiImport"
      :filterOn="false"
      @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"
      :multiple_headers="headers"
    ></multiple-row-header-table>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import MultipleRowHeaderTable from "@/components/MultipleRowHeaderTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    MultipleRowHeaderTable,
  },
  data() {
    const permission = this.$store.getters["app/ACTIONS"];
    const { block } = this.$route.params;

    return {
      title: "Nilai Mahasiswa",
      api: `student-grades/head-lecturer?blockId=${block}`,
      apiImport: "student-grades/import-grades",
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      actions: {
        import: Boolean(permission.import),
        export: Boolean(permission.export),
      },
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "name", label: "Nama Mahasiswa", sortable: true },
        { key: "na", label: "NIlai Akhir", sortable: true },
        { key: "hm", label: "Huruf Mutu", sortable: true },
        { key: "attendance", label: "Kehadiran", sortable: true },
      ],
    };
  },
  mounted() {
    const _ = this;

    if (_.$route.params) {
      if (_.$route.params.variant) {
        _.toastNotification(
          _.$route.params.variant,
          "BellIcon",
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>
